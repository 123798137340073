<template>
    <div class="">
        <div class="addcreat">
            <h1 class="addcreat-h1">Customize your plan however you need</h1>

            <div class="flex_between_box">
                <div class="addcreat-card">
                    <div>
                        <img src="../../assets/img/zhanshi0.png" alt="" />

                        <h3 class="arrcreat-head">Silver</h3>
                    </div>

                    <div class="arrcreat-bottom">
                        <span v-if="vipYing == 0" class="arrcreat-bottom-money">$300</span>
                        <span v-if="vipYing == 1" class="arrcreat-bottom-money">${{ 3000 | FilterNum }}</span>
                        <span class="arrcreat-bottomi">user/ {{
                            arrcreatfen }}</span>
                    </div>
                    <p class="arrcreat-bottomp">
                        ({{ vipYing == 0 ? 'Paid monthly' : 'Paid annually' }}, no need for auto renews)
                    </p>
                    <div class="vipqiehuan flex_center_bettwen">
                        <button @click="vipYing = 0; arrcreatfen = 'month'" :class="vipYing == 0
                            ? 'ipqiehuan_button ipqiehuan_buttonactive'
                            : 'ipqiehuan_button'
                            ">
                            Paid monthly
                        </button>
                        <button @click="vipYing = 1; arrcreatfen = 'year'" :class="vipYing == 1
                            ? 'ipqiehuan_button ipqiehuan_buttonactive'
                            : 'ipqiehuan_button'
                            ">
                            Paid annually
                        </button>
                    </div>
                    <div></div>

                    <div>
                        <button :disabled="userinfo?.userInfo?.userType == 3"
                            :class="userinfo?.userInfo?.userType == 3 ? 'black_buttonhui' : 'black_button'"
                            @click="GetStarted(1)">Buy now</button>
                    </div>
                    <ul class="arrcreat-countet">
                        <p class="arrcreat-li-gouxuan">
                            Search companies and get an instant access to company data that
                            includes:
                        </p>
                        <div>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Unlimited advanced
                                    search</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">View company
                                    profile</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company ownership
                                    structure</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company family tree</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company management</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company contacts
                                    info</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Business activities</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Industry basics</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Import & export
                                    records</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company
                                    publications</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company news</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Download online
                                    report</span>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="addcreat-card">
                    <div>
                        <img src="../../assets/img/zhanshi2.png" alt="" />

                        <h3 class="arrcreat-head">Gold</h3>
                    </div>

                    <div class="arrcreat-bottom">
                        <span v-if="vipJin == 0" class="arrcreat-bottom-money" style="color: #e58c1d">$800</span>
                        <span v-if="vipJin == 1" class="arrcreat-bottom-money"
                            style="color: #e58c1d">${{ 8000 | FilterNum }}</span>
                        <span v-if="vipJin == 0" class="arrcreat-bottomi">user/ month</span>
                        <span v-if="vipJin == 1" class="arrcreat-bottomi">user/ year</span>
                    </div>
                    <p class="arrcreat-bottomp">
                        ({{ vipJin == 0 ? 'Paid monthly' : 'Paid annually' }}, no need for auto renews)
                    </p>
                    <div class="vipqiehuan flex_center_bettwen">
                        <button @click="vipJin = 0;" :class="vipJin == 0
                            ? 'ipqiehuan_button ipqiehuan_buttonactiveyellow'
                            : 'ipqiehuan_button'
                            ">
                            Paid monthly
                        </button>
                        <button @click="vipJin = 1" :class="vipJin == 1
                            ? 'ipqiehuan_button ipqiehuan_buttonactiveyellow'
                            : 'ipqiehuan_button'
                            ">
                            Paid annually
                        </button>
                    </div>
                    <div></div>

                    <div>
                        <button :disabled="userinfo?.userInfo?.userType == 3"
                            :class="userinfo?.userInfo?.userType == 3 ? 'black_buttonhui' : 'black_button'"
                            @click="GetStarted(2)">Buy now</button>
                    </div>
                    <ul class="arrcreat-countet">
                        <p class="arrcreat-li-gouxuan">
                            Everything in Silver package, plus:
                        </p>
                        <div>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Management details</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Shareholder penetration
                                    (UBO)</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Financial info</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Financial analysis</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Credit assessment</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Industry analysis</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Market analysis</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Supply Chain info</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Check sanctions</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Company comparison</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Data export</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Monitoring service</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Discount for fresh update
                                    reports</span>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="addcreat-card">
                    <div>
                        <img src="../../assets/img/zhuansi3.png" alt="" />

                        <h3 class="arrcreat-head">
                            <span>Enterprise</span>
                        </h3>
                    </div>

                    <div class="arrcreat-bottom">
                        <p class="Contactus">Contact us</p>
                    </div>
                    <p class="arrcreat-bottomp">(for full support to your needs)</p>
                    <div class="vipqiehuan flex_center_bettwen" style="background: transparent"></div>
                    <div></div>

                    <div>
                        <a href="mailto:service@csibizinfo.com" style="color: #fff">
                            <button class="black_button">
                                Contact now
                            </button>
                        </a>
                    </div>
                    <ul class="arrcreat-countet">
                        <p class="arrcreat-li-gouxuan">Everything in Gold package, plus:</p>
                        <div>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search Company</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search Leads</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search Trade</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search People</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search Country</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search News</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Search Sanction</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Data Services</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">API Plans</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Volume Discounts</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Group Accounts with
                                    multiple users</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Customized Plans</span>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="addcreat-card">
                    <div>
                        <img src="../../assets/img/zhanshi3.png" alt="" />

                        <h3 class="arrcreat-head flex_center_bettwen" style="ju">
                            <span>Credit</span>
                            <span class="Creditpoints">Remaining <br>{{ $store.state.app.equityType }} points</span>
                        </h3>
                    </div>

                    <div class="arrcreat-bottom">
                        <span class="arrcreat-bottom-money" style="color: #1290c9">${{ (Num * 30) | FilterNum }}</span>
                    </div>
                    <div class="arrcreat-bottom-last" style="margin-bottom: 6px;">
                        <el-input v-model="Num" autocomplete="off" @input="validateInput">
                            <span slot="suffix" class="each">/ $30 each </span>
                        </el-input>
                    </div>
                    <p class="arrcreat-bottomp">(Credits charged in your account never expire)</p>

                    <div>
                        <button :disabled="this.Num == 0" :class="this.Num == 0 ? 'black_buttonhui' : 'black_button'"
                            @click="GetStarted(5)">Buy now</button>
                    </div>
                    <ul class="arrcreat-countet">
                        <p class="arrcreat-li-gouxuan">
                            Credits can be used for more data services including the followings:
                        </p>
                        <div>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Fresh investigated credit
                                    reports worldwide</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Business monitoring
                                    services</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Business process
                                    verification services</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Vendor compliance
                                    reports</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Data services</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">Market research</span>
                            </li>
                            <li class="arrcreat-li-gouxuan flex_box">
                                <i class="el-icon-check"></i><span style="padding-left: 10px">More…</span>
                            </li>


                        </div>
                    </ul>
                </div>

            </div>
        </div>
        <!-- 点击 Update data 后弹出 -->
        <el-dialog :visible.sync="purchaseDialog" center :close-on-click-modal="true" custom-class=" puy_dialog"
            :append-to-body="true">
            <div class="detail_cont">
                <el-form ref="purchaseForm" :model="purchaseForm" :rules="purchaseRules" class="form_box"
                    label-position="left">
                    <div class="purc_right">
                        <div class="pay_title m_b_20">Payment Method</div>
                        <el-form-item label="Full Name " prop="fullName" class="inpt_line">
                            <el-input v-model="purchaseForm.fullName" autocomplete="off" maxlength="64"></el-input>
                        </el-form-item>
                        <el-form-item label="Credit Card Number " prop="cardNumber" class="inpt_line m_l_20">
                            <el-input autocomplete="off" v-model="purchaseForm.cardNumber" maxlength="16"></el-input>
                        </el-form-item>
                        <el-form-item label="Exp.Month " prop="expMonth" class="inpt_line">
                            <el-select v-model="purchaseForm.expMonth" placeholder="Select" class="fin_top_sel"
                                autocomplete="off">
                                <el-option :label="item.laber" :value="item.value" v-for="(item, index) in monthOptions"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Exp.year " prop="expYear" class="inpt_line">
                            <el-select v-model="purchaseForm.expYear" placeholder="Select" class="fin_top_sel"
                                autocomplete="off">
                                <el-option :label="item" :value="item" v-for="(item, index) in yearOptions"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="CVC Code " prop="cardCode" class="inpt_line">
                            <el-input autocomplete="off" v-model="purchaseForm.cardCode" maxlength="4"></el-input>
                        </el-form-item>
                        <el-form-item label="Country " prop="country" class="inpt_line">
                            <el-select v-model="purchaseForm.country" placeholder="Select" class="fin_top_sel"
                                @change="changeCountry" autocomplete="off">
                                <el-option v-for="item in countryOptions" :key="item.countryCode" :label="item.nameEn"
                                    :value="item.countryCode"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Province / State " prop="province" class="inpt_line m_l_20">
                            <el-input v-model="purchaseForm.province" autocomplete="off"></el-input>
                            <!-- <el-select
                    v-model="purchaseForm.province"
                    placeholder="Select"
                    class="fin_top_sel"
                    no-data-text="No Data"
                    :loading="proviceArrLoading"
                    loading-text="loading..."
                  >
                    <el-option
                      :label="item.name"
                      :value="item.value"
                      v-for="(item, index) in provinceOptions"
                      :key="index.value"
                    ></el-option>
                  </el-select> -->
                        </el-form-item>
                        <el-form-item label="Postal Code" prop="zip" autocomplete="off" class="inpt_line m_l_20">
                            <el-input v-model="purchaseForm.zip"></el-input>
                        </el-form-item>

                        <div class="total_txt m_b_20">
                           <span style="width: 35%;display: inline-block;"> Total Purchase</span>
                            <span class="SelectNumm">${{ SelectNumm | FilterNum }}</span>
                        </div>
                        <!-- <div class="pay_price m_b_20" v-if="!Subscriber">$ {{ purchaseForm?.price }}</div> -->
                        <!-- <div v-if="Subscriber" class="pay_price m_b_20">
                                  $ {{ purchaseForm.price + productList[1]?.actualPrice }}
                                  <span>/year</span>
                              </div> -->

                        <div class="tip_txt m_b_40">
                            By clicking “Submit”, you agree to the
                            <span style="color: #008eda">CSI Services Agreement and Privacy</span>
                            .
                        </div>
                        <div class="btn_box">
                            <el-button class="btn_cancel" @click="btnCancel">Cancel</el-button>
                            <el-button class="btn_export" style="background: #1290c9"
                                @click="onSubmit">Submit</el-button>
                        </div>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <PermissionDialog></PermissionDialog>
    </div>
</template>
<script>
import {
    cardAuthorize,
    getPublicClientKey,
    saveUserRecharges,
    getoptions,
} from "@/api/companyApi.js";
import Header from "../layout/header.vue";
import { createScript } from "@/utils/acceptcreate";
import { getProductList, failedPay } from "@/api/api.js";
import gui_chart from "@/components/gui_chart/gui_chart.vue";
import PermissionDialog from "@/components/power/PermissionDialog.vue";

export default {
    components: { Header, gui_chart, PermissionDialog },
    data() {
        return {
            purchaseDialog: false,
            userinfo: JSON.parse(localStorage.getItem("userInfo")),
            isNew: true,
            SelectNumm: 0,
            Num: 0,
            NumAll: "",
            vipYing: 0,
            vipJin: 0,
            arrcreatfen: "month",
            purchaseForm: {
                price: "",
                loginAccount: "",
                fullName: "",
                cardNumber: "",
                expMonth: "",
                expYear: "",
                cardCode: "",
                country: "",
                province: "",
                zip: "",
            },
            ProductList: [],
            yearOptions: [],
            monthOptions: [
                { laber: "1", value: "01" },
                { laber: "2", value: "02" },
                { laber: "3", value: "03" },
                { laber: "4", value: "04" },
                { laber: "5", value: "05" },
                { laber: "6", value: "06" },
                { laber: "7", value: "07" },
                { laber: "8", value: "08" },
                { laber: "9", value: "09" },
                { laber: "10", value: "10" },

                { laber: "11", value: "11" },

                { laber: "12", value: "12" },
            ],
            countryOptions: [],
            provinceOptions: [],
            apiLoginID: "",
            clientKey: "",
            orderId: "",
            proviceArrLoading: false,
            purchaseRules: {
                fullName: [
                    {
                        required: true,
                        message: "Incorrect Full Name format",
                        trigger: "blur",
                    },
                ],
                cardNumber: [
                    {
                        required: true,
                        message: "Incorrect Credit Card Number format",
                        trigger: "blur",
                    },
                ],
                expMonth: [
                    {
                        required: true,
                        message: "Incorrect Exp.Month format",
                        trigger: "change",
                    },
                ],
                expYear: [
                    {
                        required: true,
                        message: "Incorrect Exp.Year format",
                        trigger: "change",
                    },
                ],
                cardCode: [
                    {
                        required: true,
                        message: "Incorrect CVC Code format",
                        trigger: "blur",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "Incorrect Country format",
                        trigger: "change",
                    },
                ],
                province: [
                    {
                        required: true,
                        message: "Incorrect Province / State format",
                        trigger: "change",
                    },
                ],
                zip: [
                    {
                        required: true,
                        message: "Incorrect Postal Code format",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {},
    created() {
        this.getProductList();
    },
    mounted() {
        this.getYears();
        this.getCountry();
        // 引入外部js文件 acept.js 脚本已经加载，并且 Accept 对象可用 支付环境加载
        // const scriptElement = createScript('https://jstest.authorize.net/v1/Accept.js');// dev 环境
        const scriptElement = createScript("https://js.authorize.net/v1/Accept.js"); // prod 环境
        scriptElement.onload = function () {
            // 输出xxx.js中的xxx
            console.log(window.Accept.dispatchData);
        };
    },
    methods: {
        validateInput() {
            // 获取输入的值
            const value = this.Num;

            // 使用正则表达式检查是否为整数
            if (!/^\d*$/.test(value)) {
                // 如果不是整数，清空输入框
                this.Num = "";
            }
        },
        GetStarted(item,) {
            if (item == 5 && this.Num == 0) {
                this.purchaseDialog = false;
                return;
            } else {
                this.purchaseDialog = true;
            }
            let price = 0
            let id = 0
            if (item == 1) {
                if (this.vipYing == 0) {
                    price = 300
                    id = 1
                } else {
                    price = 3000
                    id = 2
                }
            }
            if (item == 2) {
                if (this.vipJin == 0) {
                    price = 800
                    id = 3
                } else {
                    price = 8000
                    id = 4
                }
            }
            if (item == 5) {
                id = 5
            }



            this.SelectNumm = item == 5 ? this.Num * 30 : price;
            this.getPCKey();
            this.getOrdersAddPro(id, price);
        },
        getOrdersAddPro(item, price) {
            saveUserRecharges({
                vipPointsPricingId: item,
                points: item == 5 ? this.Num : null,
                paymentAmount: item == 5 ? this.Num * 30 : price,
                actualAmount: item == 5 ? this.Num * 30 : price,
                paymentMethod:1
            }).then((res) => {
                if (res.code == 200) {
                    this.orderId = res.data;
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: "error",
                    });
                }
            });
        },
        getProductList() {
            getProductList().then((res) => {
                if (res.code == 200) {
                    this.ProductList = res.data;
                } else {
                    this.ProductList = [];
                }
            });
        },
        getPCKey() {
            getPublicClientKey().then((result) => {
                console.log(result);
                
                // let data = JSON.parse(crypto.decrypt(result));
                let data = result;
                if (data && data.code && data.data) {
                    this.apiLoginID = data.data.apiLoginID;
                    this.clientKey = data.data.clientKey;
                }
            });
        },
        getYears() {
            const loadYear = new Date().getFullYear() - 1;
            const n = 11;
            for (var i = 0; i < n; i++) {
                const year = loadYear + i;
                this.yearOptions.push(year.toString());
            }
            return this.yearOptions;
        },
        //获取国家
        getCountry() {
            this.countryOptions = JSON.parse(
                localStorage.getItem("countryList")
            )[0].countryList;
        },
        changeCountry() {
            this.purchaseForm.province = "";
            this.provinceOptions = [];
            if (this.purchaseForm.country) {
                this.getProvince();
            }
        },
        //获取省市
        async getProvince() {
            this.proviceArrLoading = true;
            const params = {
                page: 1,
                pageSize: 10,
                company_country: this.purchaseForm.country,
            };
            // await getoptions(params).then(res => {
            //     if (res && res.code) {
            //         this.proviceArrLoading = false;
            //         let list = res.data.geographicalLocation.length > 0 ? res.data.geographicalLocation[0].companyProvince : [];
            //         this.provinceOptions = list;
            //     }
            // });
            this.provinceOptions = [];
        },
        //刷新菜单
        getKey(val) {
            if (val === 0) {
            }
        },
        onSubmit() {
            this.$refs.purchaseForm.validate(async (valid) => {
                if (valid) {
                    let authData = {};
                    authData.clientKey = this.clientKey;
                    authData.apiLoginID = this.apiLoginID;

                    let cardData = {};
                    cardData.fullName = this.purchaseForm.fullName;
                    cardData.cardNumber = this.purchaseForm.cardNumber;
                    cardData.month = this.purchaseForm.expMonth;
                    cardData.year = this.purchaseForm.expYear;
                    cardData.cardCode = this.purchaseForm.cardCode;

                    var secureData = {};
                    secureData.authData = authData;
                    secureData.cardData = cardData;

                    let lastResponse = null;
                    const responseHandler = (response) => {
                        try {
                            // just in case it still gets called twice, ignore the second call
                            if (response == lastResponse) {
                                // console.log("skipping duplicated responseHandler() call because it's the same response as the previous response");
                                return;
                            }
                            lastResponse = response;
                            if (response.messages.resultCode === "Error") {
                                for (let i = 0; i < response.messages.message.length; i++) {
                                    this.$message({
                                        message:
                                            response.messages.message[i].code +
                                            ": " +
                                            response.messages.message[i].text,
                                        type: "error",
                                        duration: 3000,
                                    });
                                }
                            } else {
                                this.postCardAuthorize(response.opaqueData);
                            }
                        } catch (error) {
                            console.log(error, "支付报错");
                        }
                    };
                 
                    // Accept.dispatchData(secureData, responseHandler);
                    // window.Accept.dispatchData 方法将该对象提交给支付网关进行支付
                    // 如果支付成功，则会调用 postCardAuthorize
                    window.Accept.dispatchData(secureData, responseHandler);
                } else {
                    
                    return false;
                }
            });
        },
        postCardAuthorize(opaqueData) {
            let params = {
                dataValue: opaqueData.dataValue, //必须
                dataDescriptor: opaqueData.dataDescriptor, //必须
                orderId: this.orderId, //必须
            };
            // const params = new URLSearchParams();
            // params.append('dataValue', opaqueData.dataValue);
            // params.append('dataDescriptor', opaqueData.dataDescriptor);
            // params.append('orderId', this.orderId);
            cardAuthorize(params).then((result) => {
                if (result.code == 200) {
                    this.$message({
                        message: result.msg,
                        center: true,
                        type: "success",
                    });
                    setTimeout(function () {
                        that.$router.go(0);
                    }, 1000); // 20
                } else {
                    this.$message({
                        message: result.msg,
                        center: true,
                        type: "error",
                    });
                    setTimeout(function () {
                        that.$router.go(0);
                    }, 1000); //
                    // window.location.reload();
                }
            });
        },
        //取消
        btnCancel() {
            failedPay({
                id: this.orderId,
                status: 3,
            }).then((res) => {
                this.$message({
                    message: res.msg,
                    center: true,
                    type: "success",
                });
            });
            this.purchaseDialog = false;
            this.purchaseForm.price = "";
            this.clientKey = "";
            this.apiLoginID = "";
            this.clientKey = "";
            this.orderId = "";
        },
    },
};
</script>
<style scoped>
.right_box {
    width: 100% !important;
}

.cont_m {
    background: #f5f5f5;
}

.uBmamJ5 {
    display: flex;
    align-items: center;
    height: 100%;
    height: 6.28em;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
}

.addcreat {
    padding: 2.857142857142857rem 8.5%;
    text-align: center;
    background-color: #fff;
}

.addcreat-h1 {
    font-size: 2.142857142857143rem;
    color: #1a2332;
    line-height: 2.4285714285714284rem;
    margin-bottom: 5.357142857142857rem;
    font-family: "DMSans DMSans";
}

.addcreat-h2 {
    font-size: 1.14285714rem;
    color: #8497ab;
    line-height: 1.1428571428571428rem;
    text-align: center;
    margin-bottom: 4.285714285714286rem;
}

.addcreat-card {
    padding: 2.142857142857143rem;
    width: 24%;
    max-width: 27.142857142857142rem;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 1.7142857142857142rem;
}

.addcreat-card img {
    position: absolute;
    left: 50%;
    top: -3.5%;
    transform: translateX(-50%);
}

.arrcreat-head {
    font-size: 1.8571428571428572rem;
    color: #1a2332;
    height: 2.5714285714285716rem;
    /* line-height: 2.4285714285714284rem; */
    /* line-height: 1.6428571428571428rem; */
    text-align: left;
    font-family: 'DMSans Bold';
    /* margin: 0.7142857142857143rem 0rem; */
}
.arrcreat-head span{
    font-family: 'DMSans Bold';
}
.arrcreat-headtwo {
    padding-bottom: 1.2857142857142858rem;
    /* border-bottom: 0.07142857142857142rem solid #CCCCCC; */
    text-align: left;
    font-size: 1.14285714rem;
    font-family: "DMSans DMSans";
}

.arrcreat-countet {
    padding-top: 1.4285714285714286rem;
    margin-top: 1.4285714285714286rem;
    border-top: 0.07142857142857142rem solid #cccccc;
}

.el-icon-check {
    color: #1290c9;
}

.arrcreat-li-bottom {
    font-size: 1.14285714rem;
    color: #1290c9;
    line-height: 1.4285714285714286rem;
    text-align: left;
    margin-top: 2.857142857142857rem;
    text-decoration-line: underline;
}

.arrcreat-li-gouxuan {
    font-size: 1.14285714rem;
    color: #1a2332;
    line-height: 1.4285714285714286rem;
    text-align: left;
    margin-bottom: 1.1428571428571428rem;
    display: flex;
    align-items: center;
    font-family: "DMSans DMSans";
}

.arrcreat-active {
    background: #1a2332;
    border-radius: 1.7142857142857142rem;
}

.arrcreat-active .arrcreat-head,
.arrcreat-active .arrcreat-headtwo,
.arrcreat-active .arrcreat-countet .arrcreat-li-gouxuan,
.arrcreat-active .arrcreat-countet .arrcreat-li-bottom,
.arrcreat-active .arrcreat-bottom-money {
    color: #fff;
}

.arrcreat-bottom-money {
    font-size: 2.857142857142857rem;
    color: #5d6676;

    font-family: 'DMSans Bold';
        /* font-family: 'DMSans', sans-serif; 使用 DMSans 字体 */
}

.arrcreat-bottom {
    text-align: left;
    margin-top: 1.4285714285714286rem;
    line-height: 2.4285714285714284rem;
    display: flex;
    /* overflow: hidden; */
}

.arrcreat-bottomi {
    margin-left: 0.5714285714285714rem;
    font-size: 1.14285714rem;
    color: #1A2332;
    height: 2.4285714285714284rem;
    line-height: 3.5714285714285716rem;
    font-family: "DMSans DMSans";
    /* margin-bottom: 0.7142857142857143rem; */
}

.black_button {
    height: 2.857142857142857rem;
    background: #1290c9;
    border-radius: 0.7142857142857143rem;
    line-height: 2.142857142857143rem;
    color: #fff;
    font-size: 1.2857142857142858rem;
    width: 100%;
    font-family: "DMSans DMSans";
}

.black_buttonhui {
    height: 2.857142857142857rem;
    background: #cccccc;
    border-radius: 0.7142857142857143rem;
    line-height: 2.142857142857143rem;
    color: #fff;
    font-size: 1.2857142857142858rem;
    width: 100%;
    font-family: "DMSans DMSans";
}

.arrcreat-active .black_button {
    background: #d9a677;
}

.arrcreat-bottom-last {
    margin: 1rem 0rem;
    border-radius: 0.7142857142857143rem !important;
}

/deep/ .el-input__inner:focus,
/deep/ .el-input__inner {
    border-radius: 0.7142857142857143rem !important;
    border-color: #cccccc !important;
    height: 2.857142857142857rem !important;
    font-family: "DMSans DMSans";
font-weight: 400;
color: #1A2332;
}
.arrcreat-bottom-last /deep/ .el-input__inner{
    font-size: 1.7142857142857142rem;

}
/deep/ .el-form-item {
    display: flex;
}

/deep/ .el-form--label-left .el-form-item__label {
    text-align: left;
    width: 36%;
    font-size: 1.2857142857142858rem;
    color: #1a2332;
    line-height: 2.857142857142857rem !important;
}

/deep/ .el-form-item__content {
    width: 60%;
}

/deep/ .el-select {
    width: 100%;
}

.pay_title {
    font-size: 1.4285714285714286rem;
    color: #1a2332;
    line-height: 1.9285714285714286rem;
    height: 1.8571428571428572rem;
    margin-bottom: 1.4285714285714286rem;
    font-family: 'DMSans Bold';
}

.total_txt {
    font-size: 1.2857142857142858rem;
    color: #1a2332;
    line-height: 1.7142857142857142rem;
    padding-left: 0.7142857142857143rem;
    margin-bottom: 0.7142857142857143rem;
}

.tip_txt {
    font-size: 1.14285714rem;
    color: #1a2332;
    padding-left: 1.0714285714285714rem;
}

.btn_box {
    margin-top: 2.142857142857143rem;
    width: 100%;
    text-align: center;
}

.SelectNumm {
    font-size: 2rem;
    color: #1a2332;
    font-family: "DMSans DMSans";
    font-weight: bold;
}

.each {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.2857142857142858rem;
    margin-right: 0.35714285714285715rem;
    color: #666666;
    font-family: "DMSans DMSans";
}

.active {
    background-color: #1a2332;
}

.active .arrcreat-head,
.active .arrcreat-headtwo,
.active .arrcreat-li-gouxuan,
.active .arrcreat-bottom-money {
    color: #ffffff;
}
.arrcreat-li-gouxuan span{
    font-family: "DMSans DMSans";
}
.active .arrcreat-li-bottom {
    color: #d9a677;
    font-family: "DMSans DMSans";
}

.active .black_buttonhui {
    background: #d9a677;
    color: #1a2332;
    font-family: "DMSans DMSans";
}

.active .black_button {
    background: #d9a677;
    color: #1a2332;
    font-family: "DMSans DMSans";
}

#c2f92a {
    height: 51.42857142857143rem;
    position: fixed;
    top: 0rem;
    right: 0rem;
}

.arrcreat-bottomp {
    margin-top: 0.8571428571428571rem;
    font-size: 1rem;
    color: #1a2332;
    margin-bottom: 1.2857142857142858rem;
    text-align: left;
    font-weight: 400;
    font-family: "DMSans DMSans";
}

.vipqiehuan {
    height: 2.5714285714285716rem;
    background: #ededed;
    border-radius: 1.4285714285714286rem;
    margin-bottom: 1.2857142857142858rem;
}

.ipqiehuan_button {
    height: 2.2857142857142856rem;
    color: #666666;
    font-family: "DMSans DMSans";
    font-weight: 400;
    width: 50%;
    font-size: 1.14285714rem;
    text-align: center;
    border-radius: 1.4285714285714286rem;
    background-color: transparent;
}

.ipqiehuan_buttonactive {
    color: #ffffff;
    background: linear-gradient(180deg, #5c6576 0%, #959dae 100%);
}

.ipqiehuan_buttonactiveyellow {
    color: #ffffff;
    background: linear-gradient(180deg, #e58c1d 0%, #fadb7d 100%);
}

.Contactus {
    font-size: 2.857142857142857rem;
    color: #1290c9;
    font-family: 'DMSans Bold';
}

.Creditpoints {
    width: 7.857142857142857rem;
    font-size: 1.2857142857142858rem;
    color: #1a2332;
    text-align: right;
    font-family: "DMSans DMSans" !important;
}
</style>